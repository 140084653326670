import React from 'react';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
import PauseScreenSlideShow from './PauseScreenSlideShow';
import {actionCreators as routerActions} from './../../store/RouterActions';
import './PauseScreen.css'

const PauseScreen = props => {
    return (
        <div className='pause-screen-wrapper pause-screen-slide-show' onClick={() => props.routerActions.navigate('/')}>
            <div className="pause-text-box">
                Tryk på skærmen for at vende <br/> tilbage til check in skærmen
            </div>
            <PauseScreenSlideShow slideShowId={10028} />
        </div>
    );
};
export default connect(
    state => ({state: state}),
    dispatch => ({
        routerActions: bindActionCreators(routerActions, dispatch),
    })
)(PauseScreen);