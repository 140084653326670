import React,{Component} from  'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {EmptyDay} from './../planning/Day';
import UnitBookingMenu from './UnitBookingMenu';
import connect from 'react-redux/es/connect/connect';
import {bindActionCreators} from 'redux';
import * as ResourceStore from '../../store/ResourceStore';

const _MS_PER_DAY = 1000 * 60 * 60 * 24;

class ResourceCalendarLine extends Component{

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        const plansDifferent = JSON.stringify(this.props.plans) !== JSON.stringify(nextProps.plans);
        const daysDifferent = this.props.days[0] !== nextProps.days[0] ||
            this.props.days.length !== nextProps.days.length;
    
        return plansDifferent || daysDifferent;
    }


    render() {
        console.log('Rendering calendarline');
        const props = this.props;
        const unit = props.unit ;
        const granularity = props.granularity;
        const days = props.days;
        const dayWidth = props.dayWidth;
        const plans = props.plans;
    
        const grid = days.map((d, i) => {
            return <EmptyDay key={i} size={dayWidth} highlight={false} />;
        });
        const hourGrid = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].map((d, i) => {
            return <EmptyDay key={i} size={dayWidth} highlight={false} />;
        });
    
        return (
            <div className="row" onClick={props.onClick || null}>
                <div className='plans'>
                    {granularity === 'hour' ? <PlanComponentsHour startTime={props.startTime} plans={plans} dayWidth={dayWidth} resourceActions={props.resourceActions} resState={props.resState} /> : <PlanComponents startTime={props.startTime} plans={props.plans} dayWidth={dayWidth} resourceActions={props.resourceActions} />}
                </div>
                <div className='cell-grid'>
                    {granularity === 'hour' ? hourGrid : grid}
                </div>
                <div className='name'>{unit.name}</div>
            </div>
        );
    };    
}

ResourceCalendarLine.propTypes = {
    unit: PropTypes.object.isRequired,
    granularity: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    plans: PropTypes.array,
    startTime: PropTypes.any.isRequired,
    resourceActions: PropTypes.object.isRequired,
    resState: PropTypes.object.isRequired,
};

const PlanComponentsHour = props => {
    const start = moment(props.startTime);
    const dayWidth = props.dayWidth;
    return props.plans.map((p,i) => {
        let planStart = moment(p.startTime);
        if(planStart.isBefore(start)) planStart = start;
        const startTime = moment(planStart).diff(props.startTime, 'minutes') / 60 * dayWidth;
        const duration = moment(p.endTime).diff(planStart, 'minutes') / 60;
        return <Plan key={i} dayWidth={dayWidth} startTime={startTime} duration={duration} p={p} resourceActions={props.resourceActions} resState={props.resState} />;
    })
};

PlanComponentsHour.propTypes = {
    startTime: PropTypes.object,
    plans: PropTypes.array,
    dayWidth: PropTypes.number,
    resourceActions: PropTypes.object.isRequired,
    resState: PropTypes.object.isRequired,
};

const PlanComponents = props => {
    const start = moment(props.startTime);
    const dayWidth = props.dayWidth;

    return props.plans.map((p,i) => {
        let planStart = moment(p.startTime);
        if(planStart.isBefore(start)) planStart = moment(start).add(1, 'hour');
        const planEnd = moment(p.endTime);
        const startTime = Math.ceil(((planStart - start) / _MS_PER_DAY) - 1) * dayWidth;
        const duration = Math.ceil((planEnd - planStart) / _MS_PER_DAY);
        return <Plan key={i} dayWidth={dayWidth} startTime={startTime} duration={duration} p={p} resourceActions={props.resourceActions} resState={props.resState} />;
    });
};

const Plan = props => {
    const duration = props.duration;
    const startTime = props.startTime;
    let p = props.p;
    const dayWidth = props.dayWidth;

    const toggleUnitBookingMenuBox = (p) => {
        props.resourceActions.toggleShowUnitBookingMenuBox(p);
    };

    if ((p.person === null || p.team === null) && props.resState !== undefined && props.resState.booking !== undefined){
        p = props.resState.booking
    }

    if(duration > 0){
        return(
            <div key={p.id} className='plan' style={{left: `${startTime}%`, backgroundColor: 'red', width: `${dayWidth*duration}%`}} onClick={() => toggleUnitBookingMenuBox(p)}>
                <UnitBookingMenu p={p} />
                <div className='plan-text'>
                    {`${p.personId && p.person != null ? `${p.person.name}` : ''}${p.personId && p.teamId ? ' - ' : ''}${p.teamId && p.team != null ? p.team.name : ''}`}
                </div>
            </div>
        )
    }
    return null;
};

Plan.propTypes = {
    startTime: PropTypes.object,
    duration: PropTypes.number,
    dayWidth: PropTypes.number,
    p: PropTypes.object,
    resourceActions: PropTypes.object.isRequired,
    resState: PropTypes.object.isRequired,
};

PlanComponents.propTypes = {
    startTime: PropTypes.object,
    plans: PropTypes.array,
    dayWidth: PropTypes.number,
    resourceActions: PropTypes.object.isRequired,
    resState: PropTypes.object.isRequired,
};

export default connect(
    state => ({
        resState: state[ResourceStore.reducerName]
    }),
    dispatch => ({
        resourceActions: bindActionCreators(ResourceStore.actionCreators, dispatch),
    })
)(ResourceCalendarLine);