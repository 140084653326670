import React from 'react';
import Team from './Team'
import './TeamList.css';
import ColorLegend from '../planning/ColorLegend';
import connect from 'react-redux/es/connect/connect';
import * as ResourceStore from '../../store/ResourceStore';
import moment from 'moment';

const CustomTeamList = props => {
    const units = props.state[ResourceStore.reducerName].units;
    const bookings = props.state[ResourceStore.reducerName].bookings;
    const now = moment();
    const currentBookings = bookings.filter(x => now.isSameOrAfter(x.startTime) && now.isSameOrBefore(x.endTime));

    const teams = props.state.teamState.teams;
    if(teams.length < 1) return null;

    const addUnits = team => {
        team.units = currentBookings.reduce((result,b) => {
            if(b.teamId === team.id){
                result.push(units.find(u => u.id === b.unitId));
            }
            return result;
        }, []);
    };

    const drift1 = teams.find(x => x.id === 104);
    const institution = teams.find(x => x.id === 107);
    const stenloseOgSmorum = teams.find(x => x.id === 108);
    const olstykke = teams.find(x => x.id === 109);

    const drift2 = teams.find(x => x.id === 105);
    const entrepenor = teams.find(x => x.id === 110);
    const idraet = teams.find(x => x.id === 111);
    const tomrer = teams.find(x => x.id === 112);
    const servicerTeam = teams.find(x => x.id === 113);
    
    const ledelse = teams.find(x => x.id === 106);
    const vaerkstedNatur = teams.find(x => x.id === 114);
    const raadhus = teams.find(x => x.id === 136);

    addUnits(drift1);
    addUnits(institution);
    addUnits(stenloseOgSmorum);
    addUnits(olstykke);
    addUnits(drift2);
    addUnits(entrepenor);
    addUnits(idraet);
    addUnits(tomrer);
    addUnits(servicerTeam);
    addUnits(ledelse);
    addUnits(vaerkstedNatur);
    addUnits(raadhus);

    return (
        <div className="team-list">
            {drift1 &&
                <Team
                    team={drift1}
                    width={35}
                    subTeams={[institution, stenloseOgSmorum, olstykke]}

                />
            }
            {drift2 &&
                <Team
                    team={drift2}
                    width={35}
                    subTeams={[entrepenor, idraet, tomrer, servicerTeam]}
                />
            }
            {ledelse &&
                <Team
                    team={ledelse}
                    width={30}
                    subTeams={[vaerkstedNatur, raadhus]}
                />
            }
            <ColorLegend />
        </div>
    );
};

export default connect(
    state => ({state: state})
)(CustomTeamList);