import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as ResourceStore from '../../store/ResourceStore';
// import TimeSelectForm from './TimeSelectForm';
import './UnitBookingMenu.css'

const UnitBookingMenu = (props) => {
    const { resState, resourceActions, p} = props;

    const toggleUnitBookingMenuBox = () => {
        resourceActions.toggleShowUnitBookingMenuBox(p);
    };
    const toggleDeleteBox = () => {
        resourceActions.toggleShowDeleteBox(p);
    };
    const toggleEditBox = () => {
        resourceActions.toggleShowEditBox(p);
    };

    if (!resState.showUnitBookingMenuBox || resState.bookingIdToUnitBookingMenu !== p.id) {
        return null;
    }

    // if (resState.showEditBox && resState.bookingIdToEditBox === p.id && resState.showTimeMenu) {
    //     console.log("p is: "  + p)
    //     return <TimeSelectForm/>;
    // }
    if (resState.showDeleteBox || resState.bookingIdToDelete === p.id) {
        return (
            <div className="delete-box">
                <div className="background"></div>
                <div className="text-wrapper">
                    Bekræft at du vil slette {p.person.name.trim().split(/\s+/)[0]}'s booking af {p.unit.name}
                </div>
                <button className="confirm-delete-button" onClick={() => resourceActions.deleteBooking(p.id)}>Bekræft sletning</button>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-x-circle-fill close" viewBox="0 0 16 16" onClick={(event) => { event.stopPropagation(); toggleDeleteBox(); }}>
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                </svg>
            </div>
        );
    }

    return (
        <div className="unit-booking-menu-box">
            <div className="background"></div>
            <div className="text-wrapper">
                Vil du slette eller ændre {p.person.name.trim().split(/\s+/)[0]}'s booking af {p.unit.name}?
            </div>
            <div className='unit-booking-menu-buttons'>
                <button className="unit-booking-delete-button" onClick={() => toggleDeleteBox()}>Slet</button>
                <button className="unit-booking-edit-button" onClick={() => toggleEditBox()}>Ændre tidspunkt</button>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-x-circle-fill close" viewBox="0 0 16 16" onClick={(event) => { event.stopPropagation(); toggleUnitBookingMenuBox(); }}>
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
            </svg>
        </div>
    );
};

export default connect(
    (state) => ({
        resState: state[ResourceStore.reducerName],
    }),
    (dispatch) => ({
        resourceActions: bindActionCreators(ResourceStore.actionCreators, dispatch),
    })
)(UnitBookingMenu);
