import React from 'react';
import Slider from 'react-slick';
import config from '../../config';
import connect from 'react-redux/es/connect/connect';
import PropTypes from 'prop-types';
import './PauseScreen.css'

const PauseScreenSlideShow = props => {

    const slideShow = props.state.slideState.slideShows[props.slideShowId]
    const settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 1000*10,
        swipe: false,
        swipeToSlide: false,
        arrows: false
    };

    const renderSlide = (slide) => {
        return (
            <div key={slide.id} className="pause-screen">
                <img className="pause-screen-img" src={config.server + slide.imagePath} alt=''/>
            </div>
        );
    };
    const slideComponents = slideShow && slideShow.slides.map((s) => renderSlide(s));

    return (
        <div className={`temp-pause-screen ${props.className || ''}`}>
            <Slider {...settings}>
                {slideComponents}
            </Slider>
        </div>
    );
};

PauseScreenSlideShow.propTypes = {
    slideShowId: PropTypes.number.isRequired,
    className: PropTypes.string,
};
export default connect(
    state => ({state: state})
)(PauseScreenSlideShow);